// noinspection JSUnresolvedReference

import pollReady from '@upgrade/ui-utils/utils/poll-ready';

export const heapAvailable = () => pollReady(() => Boolean(global.heap && global.heap.addUserProperties));

export const heapUpdateUserProperties = async (trackingData = {}) => {
  await heapAvailable();
  global.heap.addUserProperties(trackingData);
};
