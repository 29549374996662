import React from 'react';
import { ThemeProvider } from 'styled-components';
import { themeConfigs } from '@upgrade/themes';
import GlobalStyles from '@upgrade/styles/GlobalStyles';
import { SkipToContent } from '@upgrade/react-components';
import { OptimizelyProvider } from './utils/optimizely';
import MobileHeaderHandler from './components/MobileHeaderHandler';

// eslint-disable-next-line import/prefer-default-export
export const wrapStyles = ({ element }) => {
  return (
    <ThemeProvider theme={themeConfigs.upgrade}>
      <GlobalStyles />
      <MobileHeaderHandler />
      <SkipToContent />
      <OptimizelyProvider>{element}</OptimizelyProvider>
    </ThemeProvider>
  );
};
