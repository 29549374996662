import React, { createContext, useState, useCallback, useContext, useMemo, useEffect, useRef } from 'react';
import getClient from '@upgrade/optimizely/client';
import activateOrDecide from '@upgrade/optimizely/client/activate-or-decide';
import getFeatures from '@upgrade/optimizely/client/get-features';
import getExperiments from '@upgrade/optimizely/client/get-experiments';
import { isEmpty } from 'lodash';

import { getUuidCookie } from '@upgrade/ras';
import { heapUpdateUserProperties } from './analytics';

export const HIDE_CREDIT_LINE = 'hide_credit_line';
export const RCPLUS_HERO = 'rewards_checking_plus_hero_2024';
export const SHOW_SHOPPING_SECTION_TOP = 'show_shopping_section_top';
export const PARTNER_LOGOS = 'partner_logos';
export const HOME_HERO_REWARDS_CHECKING_CASH_REWARDS = 'home_hero_rewards_checking_cash_rewards';

const noop = () => {};

const OptimizelyContext = createContext({
  activateExperiment: noop,
  getExperiment: noop,
  trackingData: {},
  isReady: false
});

export const useOptimizely = () => {
  return useContext(OptimizelyContext);
};

export const OptimizelyProvider = ({ children }) => {
  const [experiments, setExperiments] = useState({});
  const [client, setClient] = useState(null);
  const userId = useRef(getUuidCookie());
  const trackingData = useRef();

  const activateExperiment = useCallback(
    experimentName => {
      // only activate the experiment once, if the client object was initiated, and only in the client
      if (!client || experimentName in experiments || typeof document === 'undefined') {
        return;
      }

      const variation = activateOrDecide(client, experimentName, userId.current);

      setExperiments(prev => ({
        ...prev,
        [experimentName]: variation
      }));
    },
    [client, experiments, userId]
  );

  const getExperiment = useCallback(
    experimentName =>
      client ? activateOrDecide(client, experimentName, userId.current, { disableDecisionEvent: true }) : null,
    [client, userId]
  );

  useEffect(() => {
    const initiate = async () => {
      if (client) return;
      const clientInstance = await getClient();
      setClient(clientInstance);
    };
    initiate();
  }, [client]);

  useEffect(() => {
    async function processTrackingData() {
      if (trackingData.current || typeof document === 'undefined') {
        // only process the tracking list once, and only in the client
        return;
      }

      const allExperiments = await getExperiments(userId.current);
      const allFeatureFlags = await getFeatures(userId.current);

      const flags = Object.keys(allFeatureFlags).reduce(
        (flagItems, flag) => ({ ...flagItems, [flag]: allFeatureFlags[flag].enabled }),
        {}
      );

      const result = { ...flags, ...allExperiments };

      if (!isEmpty(result)) {
        trackingData.current = result;
        heapUpdateUserProperties(result);
      }
    }
    processTrackingData();
  }, [trackingData, userId]);

  const value = useMemo(
    () => ({
      activateExperiment,
      getExperiment,
      trackingData: trackingData.current,
      isReady: Boolean(client)
    }),
    [activateExperiment, getExperiment, trackingData, client]
  );

  return <OptimizelyContext.Provider value={value}>{children}</OptimizelyContext.Provider>;
};
